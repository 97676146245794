import { useState, useEffect } from 'react';

const tickers = ['AMZN', 'AAPL', 'TSLA', 'GOOGL', 'MSFT'];
const APIKEY = "ANwJrfpSQ5Ec_3FG5Opx1Jid04Ivqgjc" || 'YOUR_API_KEY';

export const useSharePrices = () => {
  const [sharesData, setSharesData] = useState({});

  useEffect(() => {
    const ws = new WebSocket('wss://delayed.polygon.io/stocks'); // WebSocket URL

    ws.onopen = () => {
      ws.send(`{"action":"auth","params":"${APIKEY}"}`);
      tickers.forEach((ticker) => {
        ws.send(`{"action":"subscribe","params":"A.${ticker}"}`);
      });
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      data.map((msg) => {
        if (msg.ev === 'status') {
          return;
        }

        if (tickers.includes(msg.sym)) {
          const tickerPrice = msg.c;
          setSharesData((prevData) => ({
            ...prevData,
            [msg.sym]: tickerPrice,
          }));
        }
      });
    };

    ws.onerror = (error) => console.error('WebSocket Error:', error);

    return () => ws.close();
  }, []);

  // useEffect(() => {
  //   // This will log the updated ticker prices whenever sharesData changes
  //   console.log(sharesData);
  // }, [sharesData]);

  const getSharePrice = (ticker) => {
    if (sharesData.hasOwnProperty(ticker)) {
      return sharesData[ticker];
    }
    return null;
  };

  return {
    getSharePrice,
  };
};
