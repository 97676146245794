import React from "react";

import { Link } from "react-router-dom";

import "./Header.css"
import openLogo from "../OpenAppBull.png"

function Header(props) {

  const {connectToEthereum, isConnected ,disconnectFromEthereum} = props;

  return (
    <header>
      <div className="leftH">
        <img src={openLogo} alt="logo" className="logo"  />
        <Link to="/buy" className="link">
          <div className="headerItem">Buy</div>
        </Link>  
         <Link to="/sell" className="link">
          <div className="headerItem">Sell</div>
        </Link> 
        <Link to="/" className="link">
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/account" className="link">
          <div className="headerItem">Account</div>
        </Link>  
        <Link to="/guide" className="link">
          <div className="headerItem">Guide</div>
        </Link> 
      </div>
      <div className="rightH">
        <div>
          {isConnected ? (
            <button className="disConnectButton" onClick={disconnectFromEthereum} > Disconnect </button>
          ) : (
            <button className="connectButton" onClick={connectToEthereum}> Connect </button>
          ) }
        </div>
      </div>
    </header>
  );
}

export default Header;