import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const StockMarketStatus = () => {
  const [marketStatus, setMarketStatus] = useState('');

  useEffect(() => {
    const apiKey = 'BMjzXKb83ahgGmt2O2RGuG_NH5HjmV7V';

    // Define a function to fetch market status
    const fetchMarketStatus = async () => {
      try {
        const response = await axios.get(
          `https://api.polygon.io/v1/marketstatus/now?apiKey=${apiKey}`
        );

        const { market } = response.data;
        setMarketStatus(market);
      } catch (error) {
        console.error('Error fetching market status:', error);
      }
    };

    

    // Fetch market status on component mount
    fetchMarketStatus();
  }, []);

  return marketStatus;
};
