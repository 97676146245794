

import React, { useState, useEffect } from 'react';
  import { ethers } from "ethers";
  import "./App.css";
  import Buy from "./components/Buy";
  import Header from "./components/Header";
  import Sell from "./components/Sell";
  import Swap from "./components/Swap";
  import Tokens from "./components/Tokens";
  import PdfViewer from "./components/Pdf";
  
  import { Routes, Route,Router } from "react-router-dom";
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
  import { tokenizedShares,cpamm } from './utils/index';
  
  
  import BuyTable from "./components/BuyTable";
 
  
  function App() {
    const [isConnected, setIsConnected] = useState(false)
    const [OpenContract, setOpenContract] = useState(null);
    const [CpammContract, setCpammContract] = useState(null);
    const [provider, setProvider] = useState(undefined);
    const [signer, setSigner] = useState(undefined);
    const [cpammContractaddress, setCpammContractaddress] = useState('');
    const [currentAccount, setCurrentAccount] = useState('');
    const [ethBalance, setEthBalance] = useState('');
   

      // Connect to the Ethereum network
      const connectToEthereum = async () => {
        if (window.ethereum) {
          try {
            const account = await window.ethereum.request({
              method: 'eth_requestAccounts',
            });
  
            setCurrentAccount(account[0]);

          
  
            window.ethereum.on('chainChanged', () => {
              window.location.reload();
            });
  
            window.ethereum.on('accountsChanged', () => {
              window.location.reload();
            });
  
            // Create an ethers.js provider
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            
            const balance = await window.ethereum.request({
              method: 'eth_getBalance',
              params: [currentAccount, 'latest'], // 'latest' for the most recent block
            });
  
            // Convert the balance from wei to ether
            const etherBalance = ethers.utils.formatEther(balance);
            setEthBalance(etherBalance);
  
            const contractAddress = '0xD6238E48e9C5cc13cF499dc6B3dcf35706f90b7d';
         
            
            // Create a new ethers.js contract instance
            const contract = new ethers.Contract(
              contractAddress,
              tokenizedShares.abi,
              signer
            );

            const cpammContract = new ethers.Contract(
              "0xffCDBBc412d7f71Bf4A4fe47b3BD77F9FEadB6D9",
              cpamm.abi,
              signer
            );

            setCpammContractaddress('0xffCDBBc412d7f71Bf4A4fe47b3BD77F9FEadB6D9')

            setCpammContract(cpammContract);
  
            // Set the contract instance in state
            setOpenContract(contract);
            setProvider(provider);
            setSigner(signer);
            setIsConnected(true);
           
          } catch (error) {
            console.error(error);
          }
        } else {
          toast.error(`Install Metamask`, {
            position: toast.POSITION.TOP_CENTER
          });
  
        }
      };
  
   
      

   
      const disconnectFromEthereum = async () => {
    
          try {
            
            setCurrentAccount("");
            setIsConnected(false);
            
          } catch (error) {
            console.error(error);
          }
       
      };
  
   

  
  
    return (

      <div className="App">
     

        <Header connectToEthereum={connectToEthereum} isConnected={isConnected} currentAccount={currentAccount} disconnectFromEthereum= {disconnectFromEthereum} />
        <div className="mainWindow">
          <Routes >
            <Route  path="/" element={<Swap isConnected={isConnected} OpenContract={OpenContract} contractaddress={cpammContractaddress} contract = {CpammContract} currentAccount = {currentAccount} provider={provider} signer = {signer}  />} />
            <Route path="/tokens" element={<Tokens  isConnected={isConnected} />} />
            <Route path="/buy" element={<Buy isConnected={isConnected} contract = {OpenContract} currentAccount = {currentAccount} provider={provider} signer = {signer} ethBalance = {ethBalance} />} />
            <Route path="/sell" element={<Sell isConnected={isConnected} contract = {OpenContract} currentAccount = {currentAccount} provider={provider} signer = {signer} />} />
            {/* <Route path="/stocks" element={<Stocks isConnected={isConnected} address={address} />} /> */}
            <Route path="/account" element={<BuyTable isConnected={isConnected}  contract = {OpenContract} CpammContract = {CpammContract}   currentAccount = {currentAccount} provider={provider} signer = {signer}/>} />
            <Route path="/guide" element={<PdfViewer/>} />
            
          </Routes>
        </div>
       
        <ToastContainer />
  
      </div>
    )
  }
  
  export default App;
  