import React from 'react'



import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function PdfViewer() {
  const docs = [
    { uri: require("./pdf/open.pdf"),
      fileType: "pdf",
      fileName: "open.pdf"
  } // Local File
  ];

  return <DocViewer documents={docs} className='pdf' pluginRenderers={DocViewerRenderers}
         style={{ height: 500,
          width: 800 }}  
          />;
         

}

export default PdfViewer